<template>

<div class="tag">

	<span>{{ tag }}</span> <i v-if="isRemovable" class="fa fa-remove tag-remove" v-on:click="$emit('remove')" />

</div>

</template>

<script>

export default {

	props: ['tag', 'isRemovable']

}

</script>

<style scoped>

.tag {
	padding: 4px 8px;
	border-radius: 2px;
	background-color: #5B108B;
	color: #fff;
	font-size: 14px;
	display: flex;
	line-height: 14px;
	margin-right: 4px;
	align-items: center;
}

.tag-remove {
	cursor: pointer;
	margin-left: 8px;
	line-height: 16px;
	font-size: 12px;
}

</style>
