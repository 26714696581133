<template>

<div class="item" v-on:click="$emit('click')">

	<i class="item-icon fa" :class="{[clsIcon]: true}" />

	<div class="item-text">{{ text }}</div>

</div>

</template>

<script>

export default {

	props: ['icon', 'text'],

	computed: {

		clsIcon: function() {

			return 'fa-' + this.icon

		}

	}

}

</script>

<style scoped>

.item {
	display: flex;
	align-items: center;
	height: 40px;
	border-bottom: 1px solid #ccc;
	cursor: pointer;
}

.item:last-child {
	border-bottom: 0px;
}

.item-icon {
	width: 40px;
	flex-shrink: 0;
	text-align: center;
}

.item-text {
	flex-grow: 1;
	color: #333;
	font-size: 16px;
}

</style>
