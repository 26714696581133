<template>

<div class="cell" :style="{width: styleWidth}" :class="{'is-grow': !width, 'is-right': right}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['width', 'right'],

	computed: {

		styleWidth: function() {

			if (!this.width) return false

			return (this.width.indexOf('px') === -1) ? this.width + '%' : this.width

		}

	}

}

</script>

<style scoped>

.cell {
	font-size: 16px;
	color: #333;
	height: 40px;
	line-height: 40px;
	padding-left: 16px;
	border-right: 1px solid #dadada;
}

.cell:last-child {
	border-right: 0px;
}

.cell.is-right {
	text-align: right;
	padding-right: 16px;
}

.cell.is-grow {
	flex-grow: 1;
}

</style>
