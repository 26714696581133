<template>

<a-input :label="label">

	<input v-if="!autogrow" class="text" v-bind:value="value" :maxlength="maxlength" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" />
	<textarea v-if="autogrow" v-autogrow class="text" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" />

</a-input>

</template>

<script>

export default {

	props: ['label', 'value', 'autogrow', 'maxlength', 'placeholder']

}

</script>

<style scoped>

.text {
	background-color: #fff;
	border: 2px solid #dadada;
	border-radius: 4px;
	width: 100%;
	padding: 8px 4px;
	font-size: 14px;
}

textarea.text {
	line-height: 20px;
	box-sizing: initial;
	width: calc(100% - 24px);
	padding: 10px;
	resize: none;
}

</style>
