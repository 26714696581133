<template>

<div class="row">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.row {
	display: flex;
	border: 2px solid #dadada;
	border-radius: 16px;
	margin-bottom: 16px;
	background-color: #fff;
}

</style>
