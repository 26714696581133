<template>

<a-modal :is-form="true" :is-invalid="$v.$invalid" v-on:save="onSaveClick" v-on:cancel="onClose">

	<a-input-text label="Question" :autogrow="true" v-model="model.question" />
	<a-input-text label="Answer" :autogrow="true" v-model="model.answer" />
	<a-input-text label="Link" v-model="model.link" />
	<a-input-tags label="Tags" v-model="model.tags" placeholder="Enter tag..." />

</a-modal>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],
	
	data: function() {

		return {
			model: {
				id: false,
				question: '',
				answer: '',
				link: '',
				tags: []
			},
			api: 'questions'
		}

	},

	validations: {
		model: {
			question: {
				required
			},
			answer: {
				required
			},
			link: {
				required
			}
		}
	},

	methods: {

		onClose: function() {

			this.$router.push({
				name: 'Avatar'
			})

		}

	}

}

</script>

<style scoped>

</style>
