<template>

<a-table :loading="loading">

	<router-view />

	<a-table-head v-if="items.length">

		<a-table-head-cell>Title/Description</a-table-head-cell>
		<a-table-head-cell width="200px">Room</a-table-head-cell>
		<a-table-head-cell width="200px">Location</a-table-head-cell>
		<a-table-head-cell width="100px" right="true">

			<router-link :to="{name: 'LeafletsCreate'}" class="avatar-add"><i class="fa fa-plus" /></router-link>

		</a-table-head-cell>

	</a-table-head>

	<a-table-row v-for="(item, index) in items" :key="index">

		<a-table-cell>{{ item.title }} <small>{{ item.description }}</small></a-table-cell>
		<a-table-cell width="200px">

			<template v-if="item.room_id">{{ $store.getters['rooms'][item.room_id] }}</template>

			<template v-if="!item.room_id">No room.</template>

		</a-table-cell>
		<a-table-cell width="200px">

			<template v-if="item.location_id">{{ $store.getters['locations'][item.location_id] }}</template>

			<template v-if="!item.location_id">No location.</template>

		</a-table-cell>
		<a-table-cell width="100px">

			<a-table-row-actions>

				<a-table-row-actions-item icon="pencil" text="Edit" v-on:click="onEditClick(item.id)" />
				<a-table-row-actions-item icon="trash" text="Delete" v-on:click="onDeleteClick(item.id)" />

			</a-table-row-actions>

		</a-table-cell>

	</a-table-row>

	<a-table-empty v-if="!items.length" :to="{name: 'LeafletsCreate'}" button="Add leaflet">No leaflets added.</a-table-empty>

</a-table>

</template>

<script>

import axios from 'axios'

export default {

	data: function() {

		return {
			loading: true,
			items: []
		}

	},

	created: function() {

		this.fetch()

	},

	watch: {

		$path: function() {

			if (!this.$route.meta.modal) this.fetch()

		}

	},

	methods: {

		onEditClick: function(id) {

			this.$router.push({
				name: 'LeafletsEdit',
				params: {
					id: id
				}
			})
			
		},

		onDeleteClick: function(id) {

			this.$router.push({
				name: 'LeafletsDelete',
				params: {
					id: id
				}
			})

		},

		fetch: function() {

			this.loading = true

			axios.get('leaflets').then(function(response) {		

				this.items = response.data
				this.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.avatar-add {
	font-size: 18px;
	background-color: #5B108B;
	color: #fff;
	width: 32px;
	height: 32px;
	margin-top: 4px;
	display: inline-block;
	border-radius: 16px;
	text-align: center;
	line-height: 34px;
}

.featured-cell {
	display: flex;
	justify-content: center;
}

.featured {
	width: 32px;
	height: 24px;
	text-align: center;
	cursor: pointer;
	font-size: 24px;
	color: #ccc;
}

.featured.is-active {
	color: #5B108B;
}

</style>
