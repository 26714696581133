<template>

<div class="button-wrapper" :class="{'is-disabled': disabled}">

	<router-link v-if="to" :to="to" class="button" :class="{[clsTheme]: true, 'has-icon': icon && text, 'has-text': text}">

		<i class="fa" :class="{[clsIcon]: true}" v-if="icon" />
		<div class="button-text">{{ text }}</div>

	</router-link>

	<div v-if="!to" class="button" :class="{[clsTheme]: true, 'has-icon': icon && text, 'has-text': text}">

		<i class="fa" :class="{[clsIcon]: true}" v-if="icon" />
		<div class="button-text">{{ text }}</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['text', 'to', 'icon', 'disabled', 'theme'],

	computed: {

		clsIcon: function() {

			return 'fa-' + this.icon

		},

		clsTheme: function() {

			return (this.theme) ? 'theme-' + this.theme : 'theme-default'

		}

	}

}

</script>

<style scoped>

.button-wrapper.is-disabled {
	pointer-events: none;
	opacity: 0.25;
}

.button {
	background-color: #333;
	border-radius: 4px;
	color: #fff;
	padding: 0px 16px;
	line-height: 40px;
	height: 40px;
	font-size: 14px;
	cursor: pointer;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
}

.button.has-icon {
	justify-content: flex-start;
}

.button .fa {
	font-size: 24px;
}

.button.has-text .fa {
	margin-right: 16px;
}

.button.theme-plain {
	background-color: #fff;
	color: #333;
}

.button.theme-red {
	background-color: #d15353;
}

.button.theme-green {
	background-color: #52b156;
}

.button.theme-purple {
	background-color: #5B108B;
}

.button.theme-white {
	background-color: #fff;
	color: #333;
}

</style>
