<template>

<a-table :loading="loading">

	<router-view />

	<a-table-head v-if="items.length">

		<a-table-head-cell>Question</a-table-head-cell>
		<a-table-head-cell width="30">Answer</a-table-head-cell>
		<a-table-head-cell width="30">Tags</a-table-head-cell>
		<a-table-head-cell width="100px" right="true">

			<router-link :to="{name: 'AvatarQuestionCreate'}" class="avatar-add"><i class="fa fa-plus" /></router-link>

		</a-table-head-cell>

	</a-table-head>

	<a-table-row v-for="(item, index) in items" :key="index">

		<a-table-cell>{{ item.question }}</a-table-cell>
		<a-table-cell width="30">{{ item.answer }}</a-table-cell>
		<a-table-cell width="30" class="tags">

			<a-tag v-for="(tag, index) in item.tags" :key="index" :tag="tag" />

			<template v-if="!item.tags.length">No tags.</template>

		</a-table-cell>
		<a-table-cell width="100px">

			<a-table-row-actions>

				<a-table-row-actions-item icon="pencil" text="Edit" v-on:click="onEditClick(item.id)" />
				<a-table-row-actions-item icon="trash" text="Delete" v-on:click="onDeleteClick(item.id)" />

			</a-table-row-actions>

		</a-table-cell>

	</a-table-row>

	<a-table-empty v-if="!items.length" :to="{name: 'AvatarQuestionCreate'}" button="Add question">No questions added.</a-table-empty>

</a-table>

</template>

<script>

import axios from 'axios'

export default {

	data: function() {

		return {
			loading: true,
			items: []
		}

	},

	created: function() {

		this.fetch()

	},

	watch: {

		$path: function() {

			if (!this.$route.meta.modal) this.fetch()

		}

	},

	methods: {

		onEditClick: function(id) {

			this.$router.push({
				name: 'AvatarQuestionEdit',
				params: {
					id: id
				}
			})
			
		},

		onDeleteClick: function(id) {

			this.$router.push({
				name: 'AvatarQuestionDelete',
				params: {
					id: id
				}
			})

		},

		fetch: function() {

			this.loading = true

			axios.get('questions').then(function(response) {		

				this.items = response.data
				this.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.avatar-add {
	font-size: 18px;
	background-color: #5B108B;
	color: #fff;
	width: 32px;
	height: 32px;
	margin-top: 4px;
	display: inline-block;
	border-radius: 16px;
	text-align: center;
	line-height: 34px;
}

.tags {
	display: flex;
	align-items: flex-start;
}

</style>
