<template>

<div class="input">

	<div class="input-label">{{ label }}</div>
	<div class="input-content">

		<slot></slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['label']

}

</script>

<style scoped>

.input {
	margin-bottom: 16px;
}

.input-label {
	font-size: 16px;
	color: #666;
	margin-bottom: 4px;
}

</style>
