<template>

<div class="head">

	<router-link :to="{name: 'Dashboard'}" class="head-logo" />
	
	<div class="head-menu">

		<router-link :to="{name: 'Avatar'}" class="head-menu-item">Avatar</router-link>
		<router-link :to="{name: 'Videos'}" class="head-menu-item">Videos</router-link>
		<router-link :to="{name: 'Leaflets'}" class="head-menu-item">Leaflets</router-link>

	</div>

	<div class="head-user">

		<i class="head-user-icon fa fa-user-circle"></i>
		<div class="head-user-name">{{ $store.getters['name'] }} <small v-on:click="onLogoutClick">logout</small></div>

	</div>

</div>

</template>

<script>

export default {

	methods: {

		onLogoutClick: function() {

			this.$store.commit('token', '');
			window.location.href = 'https://virtual.alzheimer.staging.exesios.com'

		}

	}

}

</script>

<style scoped>

.head {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100px;
	padding: 0px 16px;
	display: flex;
	align-items: center;
	background-color: #5B108B;
}

.head-logo {
	width: 200px;
	height: 100px;
	margin-right: 16px;
	background-size: 160px auto;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(https://www.alzscot.org/themes/custom/numiko/dist/img/raster/alzscot_logo_white.png);
}

.head-menu {
	flex-grow: 1;
	display: flex;
}

.head-menu-item {
	line-height: 100px;
	color: #fff;
	text-decoration: none;
	font-size: 16px;
	font-weight: 400;
	padding: 0px 32px;
}

.head-menu-item.router-link-exact-active {
	background-color: rgba(255, 255, 255, 0.2);
}

.head-user {
	height: 100px;
	display: flex;
	align-items: center;
	min-width: 100px;
}

.head-user-icon {
	color: #fff;
	font-size: 32px;
	margin-right: 8px;
}

.head-user-name {
	font-size: 16px;
	color: #fff;
}

.head-user-name small {
	font-size: 12px;
	display: block;
	margin-top: 2px;
	cursor: pointer;
	opacity: 0.8;
}

.head-user-name small:hover {
	text-decoration: underline;
}

</style>