import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({

	state: {
		token: '',
		authorised: false,
		name: '',
		rooms: {
			1: 'Room A',
			2: 'Room B'
		},
		locations: {
			1: 'Table',
			2: 'Desk',
			3: 'Chair'
		}
	},

	getters: {

		rooms: function(state) {

			return state.rooms

		},

		locations: function(state) {

			return state.locations

		},

		authorised: function(state) {

			return state.authorised

		},

		token: function(state) {

			return state.token

		},

		name: function(state) {

			return state.name

		}

	},

	mutations: {

		authorised: function(state, value) {

			state.authorised = value

		},

		token: function(state, value) {

			state.token = (value) ? value : ''

			cookies.set('token', value)

			axios.defaults.headers.common['Authorization'] = (value) ? 'Bearer ' + value : false

		},

		name: function(state, value) {

			state.name = value

		}

	},

	actions: {

		init: function(context) {

			if (cookies.get('token')) context.commit('token', cookies.get('token'))

		}

	}

})
