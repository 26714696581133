import Vue from 'vue'
import { validationMixin } from 'vuelidate';
import axios from 'axios'

export default {

	mixins: [validationMixin],

	data: function() {

		return {
			is: {
				loaded: false,
				saving: false,
				deleting: false,
				error: false
			},
			original: {},
			validations: {}
		}

	},

	computed: {

		isNew: function() {

			return !this.model.id

		},

		isDirty: function() {

			return !this.$_.isEqual(this.model, this.original)

		}

	},

	created: function() {

		if (this.$route.params.id) {

			axios.get(this.api + '/' + this.$route.params.id).then(function(response) {

				this.model = response.data

				this.is.loaded = true

			}.bind(this), function() {

				this.is.error = true

			}.bind(this))

		} else {

			this.is.loaded = true

		}

	},

	methods: {

		updateOriginal: function() {

			Vue.set(this, 'original', this.$util.clone(this.model))

		},

		onUndoClick: function() {

			Vue.set(this, 'model', this.$util.clone(this.original))

		},

		onSaveClick: async function() {

			this.$v.$touch()

			if (this.$v.$invalid) {

				this.is.error = true

			} else {
				
				this.is.error = false

				this.is.saving = true

				await axios[(this.model.id) ? 'put' : 'post'](this.api + ((this.model.id) ? '/' + this.model.id : ''), this.model).then(function() {

					this.onClose()

				}.bind(this), function() {

					this.is.error = true

				}.bind(this))

			}
			
		},

		onDeleteClick: async function() {

			this.is.deleting = true

		}

	}

}