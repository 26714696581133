<template>

<div class="actions">

	<div class="actions-trigger">
		
		<i class="fa fa-ellipsis actions-trigger-button" v-on:click.stop="expanded = !expanded"></i>
		
	</div>

	<div class="actions-items" v-if="expanded">
		
		<slot></slot>
		
	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			expanded: false
		}

	},

	created: function() {

		window.addEventListener('click', this.onClose.bind(this))

	},

	beforeDestroy: function() {

		window.removeEventListener('click', this.onClose.bind(this))

	},

	methods: {

		onClose: function() {

			this.expanded = false

		}
	
	}

}

</script>

<style scoped>

.actions {
	width: 100%;
}

.actions-trigger {
	display: flex;
	width: 100%;
	justify-content: right;
	padding-right: 8px;
}

.actions-trigger-button {
	height: 24px;
	font-size: 24px;
	color: #666;
	line-height: 24px;
	cursor: pointer;
	user-select: none;
}

.actions-items {
	position: absolute;
	right: 16px;
	top: 32px;
	background-color: #fff;
	border: 2px solid #dadada;
	border-radius: 4px;
	width: 160px;
	box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
}

</style>
