<template>

<a-modal :is-form="true" :is-invalid="$v.$invalid" v-on:save="onSaveClick" v-on:cancel="onClose">

	<a-input-text label="Title" :maxlength="128" v-model="model.title" />
	<a-input-text label="Description" :autogrow="true" v-model="model.description" />
	<a-input-text label="Link" v-model="model.link" />
	<a-input-select label="Room" v-model="model.room_id" :options="$store.getters['rooms']" placeholder="Select room" />
	<a-input-select label="Location" v-model="model.location_id" :options="$store.getters['locations']" placeholder="Select location" />

</a-modal>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],
	
	data: function() {

		return {
			model: {
				id: false,
				title: '',
				description: '',
				link: '',
				room_id: 0,
				location_id: 0
			},
			api: 'leaflets'
		}

	},

	validations: {
		model: {
			title: {
				required
			},
			description: {
				required
			},
			link: {
				required
			}
		}
	},

	methods: {

		onClose: function() {

			this.$router.push({
				name: 'Leaflets'
			})

		}

	}

}

</script>

<style scoped>

</style>
