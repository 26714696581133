export default {

	computed: {

		$path: function() {

			return this.$route.path

		}

	}

}