<template>

<a-input :label="label">

	<div class="toggle">
		<div class="toggle-option" :class="{'is-active': value === 1}" v-on:click="$emit('input', 1)">Yes</div>
		<div class="toggle-option" :class="{'is-active': value === 0}" v-on:click="$emit('input', 0)">No</div>
	</div>

</a-input>

</template>

<script>

export default {

	props: ['label', 'value']

}

</script>

<style scoped>

.toggle {
	background-color: #fff;
	width: 100%;
	display: flex;
	flex-direction: row;
}

.toggle-option {
	padding: 8px 4px;
	font-size: 14px;
	width: 50%;
	border: 2px solid #dadada;
	text-align: center;
	cursor: pointer;
}

.toggle-option:first-child {
	border-radius: 4px 0px 0px 4px;
	border-right: 0px;
}

.toggle-option:last-child {
	border-radius: 0px 4px 4px 0px;
	border-left: 0px;
}

.toggle-option.is-active {
	color: #fff;
	background-color: #5B108B;
	border-color: #5B108B;
}

</style>
