import Vue from 'vue'
import Router from 'vue-router'

import Dashboard from '@/components/screen/Dashboard'

import Avatar from '@/components/screen/Avatar'
import Videos from '@/components/screen/Videos'
import Leaflets from '@/components/screen/Leaflets'

import ModalQuestion from '@/components/modal/Question'
import ModalVideo from '@/components/modal/Video'
import ModalLeaflet from '@/components/modal/Leaflet'

import ModalDelete from '@/components/modal/Delete'

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		{ name: 'Dashboard', path: '/', meta: {}, component: Dashboard },
		{ name: 'Avatar', path: '/avatar', meta: {}, component: Avatar, children: [
			{ name: 'AvatarQuestionCreate', path: '/avatar/create', meta: { modal: true }, component: ModalQuestion },
			{ name: 'AvatarQuestionEdit', path: '/avatar/:id', meta: { modal: true }, component: ModalQuestion },
			{ name: 'AvatarQuestionDelete', path: '/avatar/:id/delete', meta: { parent: 'Avatar', modal: true, api: 'questions' }, component: ModalDelete },
		] },
		{ name: 'Videos', path: '/videos', meta: {}, component: Videos, children: [
			{ name: 'VideosCreate', path: '/videos/create', meta: { modal: true }, component: ModalVideo },
			{ name: 'VideosEdit', path: '/videos/:id', meta: { modal: true }, component: ModalVideo },
			{ name: 'VideosDelete', path: '/videos/:id/delete', meta: { parent: 'Videos', modal: true, api: 'videos' }, component: ModalDelete },
		] },
		{ name: 'Leaflets', path: '/leaflets', meta: {}, component: Leaflets, children: [
			{ name: 'LeafletsCreate', path: '/leaflets/create', meta: { modal: true }, component: ModalLeaflet },
			{ name: 'LeafletsEdit', path: '/leaflets/:id', meta: { modal: true }, component: ModalLeaflet },
			{ name: 'LeafletsDelete', path: '/leaflets/:id/delete', meta: { parent: 'Leaflets', modal: true, api: 'leaflets' }, component: ModalDelete },
		] }
	]
})