<template>

<div class="content">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>


<style scoped>

.content {
	position: fixed;
	top: 100px;
	left: 0px;
	width: 100%;
	bottom: 0px;
	overflow: auto;
	padding: 32px;
	background-color: #fff;
}

</style>