<template>

<div class="empty">

	<i class="empty-icon fa fa-exclamation-circle"></i>

	<div class="empty-text">
		
		<slot></slot>

	</div>

	<a-button :to="to" theme="purple" :text="button" />

</div>

</template>

<script>

export default {

	props: ['to', 'button']

}

</script>

<style scoped>

.empty {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	max-width: 320px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.empty-icon {
	font-size: 128px;
	color: #ccc;
	margin-bottom: 32px;
}

.empty-text {
	font-size: 24px;
	font-weight: 500;
	color: #ccc;
	margin-bottom: 32px;
}

</style>
