<template>

<div id="app">

	<com-head v-if="authorised" />

	<i class="fa fa-spinner fa-spin app-loading" v-if="!authorised" />

	<com-content v-if="authorised">

		<router-view></router-view>

	</com-content>

	<div class="app-login" v-if="!authorised && !loading">

		<p>Login with your <b>Alzheimer Scotland</b> account to get started.</p>

		<a-button text="Login" theme="white" v-on:click.native="login" />

	</div>

</div>

</template>

<script>

import axios from 'axios'

import comHead from './components/Head'
import comContent from './components/Content'

export default {

	name: 'App',

	components: {
		'com-head': comHead,
		'com-content': comContent
	},

	data: function() {

		return {
			loaded: false,
			loading: true
		}

	},

	computed: {

		authorised: function() {

			return this.$store.getters['authorised']

		}

	},

	created: async function() {

		await this.$store.dispatch('init')

		if (this.$route.query.code) {

			this.validate()

		} else if (this.$store.getters['token']) {

			this.authorise()

		} else {

			this.loading = false

		}

	},

	methods: {

		validate: async function() {

			await axios.post('login', {
				state: this.$route.query.state,
				code: this.$route.query.code
			}).then(function(response) {

				this.$store.commit('authorised', true)
				this.$store.commit('token', response.data.token)
				this.$store.commit('name', response.data.name)

				this.$router.push({
					name: 'Dashboard'
				})

			}.bind(this), function() {

				this.$store.commit('token', false)

			}.bind(this))

		},

		authorise: async function() {

			await axios.get('user').then(function(response) {

				this.$store.commit('authorised', true)
				this.$store.commit('name', response.data.name)
				
			}.bind(this), function() {

				this.$store.commit('token', false)

			}.bind(this))

		},

		login: async function() {

			this.loading = true

			await axios.get('login').then(function(response) {

				window.location.href = response.data.redirect

			}, function() {

				//

			})

		}

	}

}

</script>

<style scoped>

#app {
	background-color: #5B108B;
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.app-loading {
	font-size: 32px;
	position: absolute;
	left: 50%;
	top: 50%;
	color: #fff;
	transform: translate(-50%, -50%);
}

.app-login {
	width: 320px;
	position: absolute;
	left: 50%;
	background-color: #5B108B;
	top: 50%;
	transform: translate(-50%, -50%);
	background-position: 0% 0%;
	background-size: auto 64px;
	background-repeat: no-repeat;
	background-image: url(https://www.alzscot.org/themes/custom/numiko/dist/img/raster/alzscot_logo_white.png);
	padding-top: 96px;
}

.app-login p {
	color: #fff;
	margin-bottom: 32px;
	font-size: 16px;
	line-height: 20px;
}

</style>
