<template>

<div class="head">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.head {
	display: flex;
	border: 2px solid #dadada;
	background-color: #dadada;
	border-radius: 16px;
	margin-bottom: 16px;
	align-items: center;
}

</style>
