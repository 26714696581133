<template>

<div class="table" :class="{'is-loading': loading}">

	<i class="fa fa-spinner fa-spin table-loading" v-if="loading" />

	<template v-if="!loading">

		<slot></slot>

	</template>

</div>

</template>

<script>

export default {

	props: ['loading']

}

</script>

<style scoped>

.table {
	min-height: 100%;
}

.table-loading {
	font-size: 32px;
	position: absolute;
	left: 50%;
	top: 50%;
	color: #5B108B;
	transform: translate(-50%, -50%);
}

</style>
