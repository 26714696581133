<template>

<div class="cell" :style="{width: styleWidth, backgroundImage: styleBackground}" :class="{'no-padding': noPadding, 'is-grow': !width}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['width', 'noPadding', 'background'],

	computed: {

		styleBackground: function() {

			return (this.background) ? 'url(' + this.background + ')' : false

		},

		styleWidth: function() {

			if (!this.width) return false

			return (this.width.indexOf('px') === -1) ? this.width + '%' : this.width

		}

	}

}

</script>

<style scoped>

.cell {
	padding: 16px;
	border-right: 1px solid #dadada;
	font-size: 16px;
	color: #333;
	line-height: 24px;
	background-position: 50% 50%;
	background-size: cover;
}

.cell.is-grow {
	flex-grow: 1;
}

.cell.no-padding {
	padding: 0px;
}

.cell:last-child {
	border-right: 0px;
}

.cell >>> small {
	display: block;
	font-size: 12px;
	margin-top: 4px;
	line-height: 16px;
	opacity: 0.8;
}

</style>
